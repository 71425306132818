<template>
    <div class="relative pb-5">
        <LoadingComponent :isLoading="loading" />
        <div class="flex items-center p-5 mb-3 bg-white rounded-lg shadow-lg">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-2">
                    <label class="text-gray-400">
                        藍新交易代碼
                        <el-input v-model="filterOpts.order_id"
                                  size="small"
                                  clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        會員 手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country"
                                           size="small"
                                           placeholder="请选择">
                                    <el-option v-for="item in phoneOptions"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone"
                                          size="small"
                                          clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-2">
                    <label class="text-gray-400">
                        狀態
                        <el-select v-model="filterOpts.status"
                                   size="small"
                                   class="w-full"
                                   clearable>
                            <el-option v-for="(status, index) in payStatusOptions"
                                       :key="index"
                                       :label="status.label"
                                       :value="status.value">
                                {{ status.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        付款日期 範圍
                        <el-date-picker v-model="dateInputs"
                                        size="small"
                                        type="daterange"
                                        value-format="yyyy-MM-dd"
                                        range-separator="至"
                                        start-placeholder="開始日期"
                                        end-placeholder="结束日期">
                        </el-date-picker>
                    </label>
                </div>
                <div class="flex items-end col-span-2">
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[30px] flex-1"
                            @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[30px] flex-1"
                            @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <table class="myTable">
                <thead>
                    <tr>
                        <th>藍新交易代碼</th>
                        <th>會員 暱稱</th>
                        <th>付款方式</th>
                        <th>付款原因</th>
                        <th>金額</th>
                        <th>狀態</th>
                        <th>建立時間</th>
                        <th>付款時間</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in datas"
                        :key="index"
                        class="text-center transition duration-200">
                        <td>
                            {{ item.order_id }}
                            <btn v-if="item.type === 'CREDIT' && item.status === 1"
                                 size="text-xs px-4 py-2 rounded min-w-[70px] max-w-[200px] block mx-auto"
                                 customClass=""
                                 @onClick="openDialog(item.order_id)">
                                退刷
                            </btn>
                        </td>
                        <td>{{ item.user.name }}</td>
                        <td>{{ item.type | paymentType }}</td>
                        <td>{{ item.reason | paymentCourse }}</td>
                        <td>${{ item.amount | formatCurrency }}</td>
                        <td>{{ item.status | paymentStatus }}</td>
                        <td>{{ item.created_at | formatDateTime }}</td>
                        <td>{{ item.pay_time | formatDateTime }}</td>
                    </tr>
                </tbody>
            </table>
            <div class="flex justify-center mt-3 mb-3">
                <MyPagination :paginationData="paginationData"
                              :currentPage="currentPage"
                              @onPageSizeChange="pageSizeChange"
                              @onCurrentPageChange="currentPageChange" />
            </div>
        </div>
        <my-dialog :showDialog="showDialog"
                   :customClass="'top-1/4 z-[70]'"
                   :customBlackBgClass="'z-[60]'"
                   @onCloseDialog="closeDialog">
            <div class="w-full p-5 text-sm bg-white rounded-lg md:p-10">
                <h2 class="text-lg text-center">請輸入退刷金額</h2>
                <label>訂單編號</label>
                <el-input v-model="form.orderId"
                          :disabled="!$isEmpty(form.orderId)"></el-input>
                <label>退刷金額</label>
                <el-input v-model.number="form.amount"
                          type="number"></el-input>
                <p class="text-red-500">請確認退刷金額 點擊確認後即無法取消</p>
                <div class="flex justify-center mt-5">
                    <button :disabled="closeCreditCardLoding"
                            class="border border-black px-3 py-0.5 rounded mr-5 disabled:bg-gray-300 disabled:cursor-not-allowed disabled:text-white disabled:border-gray-300"
                            @click.prevent="closeDialog">
                        關閉
                    </button>
                    <button v-loading="closeCreditCardLoding"
                            :disabled="closeCreditCardLoding"
                            class="bg-orange-600 px-3 py-0.5 rounded text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                            @click.prevent="closeCreditCard">
                        確認
                    </button>
                </div>
            </div>
        </my-dialog>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// 對帳相關 filters
import "./filterAccount";
// 對帳相關 configs
import accountConfigs from "@/config/accountConfig.js";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// 導入自定義 按鈕
import Btn from "@/components/Button.vue";
// 導入自定義彈窗組件
import myDialog from "@/components/Dialog.vue";

// init search Filter options
const defaultFilterOpts = {
    order_id: "",
    phone_country: "886",
    phone: "",
    status: "",
    start_date: "",
    end_date: "",
};
export default {
    name: "StoredList",
    components: {
        LoadingComponent,
        MyPagination,
        Btn,
        myDialog,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const request = { ...this.filterOpts };
            // 過濾空值
            if (!request.start_date) {
                delete request.start_date;
                delete request.end_date;
            }
            const phone = this.filterOpts.phone
                ? `${this.filterOpts.phone_country}${this.filterOpts.phone}`
                : "";
            return {
                ...request,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };
        },
        // 付款狀態 options
        payStatusOptions() {
            const status = accountConfigs.payStatus;
            const result = [
                {
                    label: "全部",
                    value: "",
                },
            ];
            for (const key in status) {
                result.push({
                    label: status[key],
                    value: key,
                });
            }
            return result;
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: true,
            datas: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 1,
            filterOpts: { ...defaultFilterOpts },
            dateInputs: [], // 時間範圍
            form: {},
            // 顯示退刷彈窗
            showDialog: false,
            // 退刷 api loading
            closeCreditCardLoding: false,
        };
    },
    methods: {
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .SearchPaymentsAPI(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },
        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.dateInputs = [];
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 關閉彈窗
        closeDialog() {
            this.showDialog = false;
            this.form = {
                orderId: this.datas[0].order_id,
            };
        },
        // 開啟彈窗
        openDialog(orderId) {
            this.showDialog = true;
            this.form = {
                orderId: orderId,
            };
        },
        /**
         * 退刷 api
         */
        async closeCreditCard() {
            if (this.form.amount !== undefined && this.form.amount <= 0) {
                this.$message({
                    type: "error",
                    message: "請輸入正確退刷金額",
                });
                return;
            }
            if (this.$isEmpty(this.form.orderId)) {
                this.$message({
                    type: "error",
                    message: "請輸入正確訂單編號",
                });
                return;
            }
            this.closeCreditCardLoding = true;
            try {
                await this.$api.CloseCreditCardApi({
                    amount: parseInt(this.form.amount),
                    orderId: this.form.orderId,
                });
                this.form = {};
                this.showDialog = false;
                this.$message({
                    type: "success",
                    message: "退刷成功",
                });
            } catch (err) {
                if (err.response.data) {
                    if (err.response.data.error.message) {
                        return this.$message({
                            type: "error",
                            message: err.response.data.error.message,
                        });
                    }
                }
                return this.$message({
                    type: "error",
                    message: "退刷失敗",
                });
            } finally {
                this.closeCreditCardLoding = false;
            }
        },
    },
    watch: {
        dateInputs(val) {
            if (val === null || val.length === 0) {
                this.filterOpts.start_date = "";
                this.filterOpts.end_date = "";
                return;
            }
            this.filterOpts.start_date = val[0];
            this.filterOpts.end_date = val[1];
        },
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
        "form.amount"(val) {
            // 判斷沒有找到資料時不往下執行
            if (this.datas.length === 0) {
                return;
            }
            // 判斷只有一筆訂單時 且退刷金額大於 訂單金額時觸發
            if (this.datas.length === 1 && val > this.datas[0].amount) {
                return this.$set(this.form, "amount", this.datas[0].amount);
            }
        },
    },
    mounted() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}

.phone-select {
    width: 150px;
    margin-right: 5px;
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
